<mt-dynamic-form-input-wrapper [parentControl]="thisControl" [formGroup]="getGroup()">
    <br />
    <!-- input element -->
    <input type="date" class="hidden" [formControlName]="config.name" [value]="config.value" />
    <input
        #input
        type="datetime-local"
        class="form-control"
        (blur)="onBlur()"
        (keydown.enter)="updateDateValue()"
        [value]="displayValue"
        [attr.disabled]="isDisabled ? true : null"
    />
    <mt-dynamic-form-validation-message
        [errorMessage]="errorMessage"
        [insideBoxValidation]="config.insideBoxValidation"
    ></mt-dynamic-form-validation-message>
</mt-dynamic-form-input-wrapper>

