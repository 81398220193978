<dynamic-form-input-wrapper [formGroup]="getGroup()">
    <!-- input element -->
    <span class="control-label">
        <span class="dynamic-field-label custom-url-label">
            {{config.label}}
        </span>
    </span>
    <div class="input-group">
        <span class="input-group-addon">https://</span>
        <input type="text" class="hidden" [formControlName]="config.name" [value]="config.value" />
        <input #inputElement type="text" (focus)="onFocus()" (blur)="onBlur()" [attr.placeholder]="config.placeholder"
            class="form-control" (keyup)="setFieldValue()" />
    </div>
    <!-- maxlength elements -->
    <span *ngIf="!config.labelLeft && hasMaxLength()" [style.visibility]="isFocused && hasMaxLength() ? null : 'hidden'"
        class="small pull-right" [class.errortext]="hasError('maxlength')"
        [class.inside-box-maxlength]="config.insideBoxValidation">{{ getMaxLengthText() }}</span>
    <span *ngIf="config.labelLeft" [style.visibility]="isFocused && hasMaxLength() ? null : 'hidden'"
        class="small pull-right" [class.errortext]="hasError('maxlength')"
        [class.inside-box-maxlength]="config.insideBoxValidation">{{ getMaxLengthText() }}</span>
    <!-- validation elements -->
    <div *ngIf="config.validators">
        <div *ngIf="!showRequired() && hasError('required')" class="small errortext" [style.position]="'absolute'"
            [class.inside-box-validation]="config.insideBoxValidation">
            {{ config.label }} is required
        </div>
        <div *ngIf="hasError('minlength')" class="small errortext" [style.position]="'absolute'"
            [class.inside-box-validation]="config.insideBoxValidation">
            {{ config.label }} must be at least
            {{ config.validators.minlength }} characters
        </div>
    </div>
</dynamic-form-input-wrapper>
