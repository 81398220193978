import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginComponent, ForgotPasswordComponent, ResetPasswordComponent, AdminAccessComponent } from '@mt-ng2/login-module';
import { UserVerifyEmailComponent } from 'admin-portal/users/user-verify-email/user-verify-email.component';
import { appPaths } from './admin-portal/default-routes/app-paths.library';
import { MtCanLoadAuthGuard } from '@mt-ng2/auth-module';
import { MatchScheduleScreenComponent } from './tournament-portal/match-schedule-screen/match-schedule-screen.component';

const appRoutes: Routes = [
    { path: appPaths.login, component: LoginComponent, title: 'Login' },
    { path: appPaths.forgotPassword, component: ForgotPasswordComponent, title: 'Forgot Password' },
    { path: appPaths.resetPassword, component: ResetPasswordComponent, title: 'Reset Password' },
    { path: appPaths.adminAccess, component: AdminAccessComponent, title: 'Admin Access' },
    { path: appPaths.verifyEmail, component: UserVerifyEmailComponent, title: 'Verify Email' },
    {
        path: 'accessibility',
        pathMatch: 'prefix',
        loadChildren: () => import('./accessibility-pages/accessibility-pages.module').then((m) => m.AccessibilityPortalModule),
    },
    { path: '', loadChildren: () => import('./public-pages/public-pages.module').then((m) => m.PublicPagesModule) },
    {
        canLoad: [MtCanLoadAuthGuard],
        path: 'portal',
        loadChildren: () => import('./admin-portal/admin-portal.module').then((m) => m.AdminPortalModule),
    },
    {
        canLoad: [MtCanLoadAuthGuard],
        path: 'tournament',
        loadChildren: () => import('./tournament-portal/tournament-portal.module').then((m) => m.TournamentPortalModule),
    },
    { canLoad: [MtCanLoadAuthGuard], path: 'match-display', component: MatchScheduleScreenComponent },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forRoot(appRoutes, { onSameUrlNavigation: 'reload' })],
})
export class AppRoutingModule {}
