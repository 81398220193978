import { Component, ErrorHandler, Injector, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { NotificationsService } from '@mt-ng2/notifications-module';
import { AuthService, AuthConfig } from '@mt-ng2/auth-module';
import { UserService } from '../user.service';

@Component({
    selector: 'user-verify-email',
    template: '',
})
export class UserVerifyEmailComponent implements OnInit {
    verificationKey?: string;
    userId?: number;
    badKeyError = `Oops something went wrong!
    - Probably the key has been used or expired.`;

    protected authService: AuthService;
    protected router: Router;
    public route: ActivatedRoute;
    protected notificationsService: NotificationsService;
    protected globalErrorHandler: ErrorHandler;
    protected authConfig: AuthConfig;
    protected userService: UserService

    constructor(injector: Injector) {
        this.authService = injector.get(AuthService);
        this.authConfig = injector.get(AuthConfig);
        this.router = injector.get(Router);
        this.route = injector.get(ActivatedRoute);
        this.notificationsService = injector.get(NotificationsService);
        this.globalErrorHandler = injector.get(ErrorHandler);
        this.userService = injector.get(UserService);
    }

    ngOnInit(): void {
        this.route.queryParams.subscribe((params) => {
            this.verificationKey = params['verificationKey'];
            this.userId = params['userId'];
        });
        if (!this.verificationKey) {
            void this.router.navigate([this.authConfig.paths.loginPath]);
        }
        
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
        this.userService.verifyEmail(this.verificationKey as string, this.userId).subscribe({
            next: () => {
                this.success();
                void this.router.navigate([this.authConfig.paths.loginPath]);
            },
            error: (error) => {
                if (error.status === 418) {
                    this.notificationsService.error('Validation link is invalid');
                    console.error(this.badKeyError);
                } else if (error.status === 400) {
                    if (error.error) {
                        if (typeof error.error === 'string') {
                            this.error(error.error as string);
                        } else if (typeof error.error?.ModelState?.Service === 'string') {
                            this.error(error.error.ModelState.Service as string);
                        } else {
                            this.globalErrorHandler.handleError(error);
                        }
                    } else {
                        this.error('We could not verify your email. Please reach out to the system administrator for assistance.');
                        console.error(this.badKeyError);
                    }
                }
            },
        });
        
    }

    error(msg?: string): void {
        if (!msg) {
            this.notificationsService.error('Save Failed');
        } else {
            this.notificationsService.error(msg);
        }
    }

    success(): void {
        this.notificationsService.success('Your email has been verified.');
    }
}
