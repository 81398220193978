import {
  provideTransloco,
  TranslocoModule
} from '@jsverse/transloco';
import { NgModule } from '@angular/core';
import { TranslocoHttpLoader } from './transloco-loader';
import { environment } from './environments/environment';
import { provideTranslocoPersistLang } from '@jsverse/transloco-persist-lang';

@NgModule({
  exports: [ TranslocoModule ],
  providers: [
    provideTransloco({
        config: {
            prodMode: environment.production,
            availableLangs: [
                { id: 'en', label: 'English' },
                { id: 'ar', label: 'اَلْعَرَبِيَّةُ' },
                { id: 'es', label: 'Español' },
                { id: 'de', label: 'Deutsche' },
                { id: 'fr', label: 'Français' },
                { id: 'pt', label: 'Português' },
                { id: 'zh-CN', label: '中国 - 简体中文' }
            ],
            // Remove this option if your application doesn't support changing language in runtime.
            reRenderOnLangChange: true,
            fallbackLang: 'en',
            defaultLang: 'en',
            missingHandler: {
                useFallbackTranslation: true,
            },
        },
        loader: TranslocoHttpLoader
    }),
    provideTranslocoPersistLang({
        storage: {
            useValue: localStorage,
        },
    }),
  ],
})
export class TranslocoRootModule {}
