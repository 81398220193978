import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment-timezone';

@Pipe({
  name: 'convertToTimezone'
})
export class ConvertToTimezonePipe implements PipeTransform {
  transform(date: Date | string, timezone: string | null): Date | null {
    if (!date) { return null; }
    if (typeof date === 'string') { date = new Date(date); }
    if (!timezone) { return date; }

    // Extract components from the input date
    const year = date.getFullYear();
    const month = date.getMonth(); // Note: month is 0-indexed
    const day = date.getDate();
    const hour = date.getHours();
    const minute = date.getMinutes();
    const second = date.getSeconds();

    // Create a moment object using the extracted components in the target timezone
    const targetTime = moment.tz({ year, month, day, hour, minute, second }, timezone);

    // Convert the target time to the local timezone
    const localTime = targetTime.clone().local();

    // Return the local time as a JavaScript Date object
    return localTime.toDate();
  }
}
