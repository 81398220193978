import { Component, EventEmitter, Injector, Input, OnChanges, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { HttpResponse } from '@angular/common/http';
import { IAddressContainer } from '@mt-ng2/dynamic-form';
import { SearchParams } from '@mt-ng2/common-classes';
import { Observable } from 'rxjs';
import { IAddress } from '@model/interfaces/address';
import { CommonAddressesComponent } from '@mt-ng2/entity-components-addresses';
import { CommonService } from '@common/services/common.service';
import { IState } from '@model/interfaces/state';

export interface IHasAddresses {
    getAddresses(entityId: number, searchParams: SearchParams): Observable<HttpResponse<IAddressContainer[]>>;
    saveAddress(entityId: number, address: IAddressContainer): Observable<number>;
    deleteAddress(entityId: number, addressId: number): Observable<object>;
}

@Component({
    selector: 'app-extended-common-addresses',
    templateUrl: './extended-common-addresses.component.html',
})
export class ExtendedCommonAddressesComponent extends CommonAddressesComponent implements OnInit, OnChanges {
    @Input() override address?: IAddress;
    @Input() override canEdit!: boolean;
    @Input() override showDelete!: boolean;
    @Input() override allowInternationalAddresses!: boolean;

    @Output() override saved = new EventEmitter<IAddressContainer>();
    @Output() override deleted = new EventEmitter<IAddressContainer>();

    states: IState[] = [];

    constructor(
        private formBuilder: UntypedFormBuilder,
        private commonService: CommonService,
        protected override injector?: Injector
    ) {
        super();
    }

    override ngOnInit(): void {
        this.commonService.getStates().subscribe((states) => {
            this.states = states;
        });

        super.ngOnInit();
    }

    saveTheAddress(addressContainer: IAddressContainer): void {
        const formGroup = this.formBuilder.group({
            Id: addressContainer['Id'],
            Address1: addressContainer['Address1'],
            Address2: addressContainer['Address2'],
            City: addressContainer['City'],
            Zip: addressContainer['Zip'],
            CountryCode: addressContainer['CountryCode'],
            Province: addressContainer['Province'] ?? '',
        });

        const stateCode = addressContainer['StateCode'] as string;
        if (stateCode?.length > 0) {
            const chosenState = this.states.find((state) => state.StateCode === stateCode);
            formGroup.addControl('StateId', this.formBuilder.control(chosenState.Id));
            addressContainer['StateId'] = chosenState.Id;
        }

        super.saveAddress(formGroup);
        this.saved.emit(addressContainer);
    }
}
