import { Component, inject, Injector, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { VirtualTypeAheadGetItemsFunction } from '@mt-ng2/type-ahead-control';
import { ISelectionChangedEvent, ITypeAheadAPI } from '@mt-ng2/type-ahead-control';
import { CustomFormControlComponentBase } from '@mt-ng2/dynamic-form';

import { OrganizationService } from 'admin-portal/organizations/services/organization.service';
import { TabSharedService } from 'admin-portal/teams/services/tab-shared.service';

import { IOrganization } from '@model/interfaces/organization';


@Component({
    templateUrl: './organization-type-ahead-input.component.html',
})
export class OrganizationTypeAheadInputComponent extends CustomFormControlComponentBase implements OnInit, AfterViewInit, OnDestroy {
    typeAheadApi: ITypeAheadAPI;
    getItemsForTypeAhead: VirtualTypeAheadGetItemsFunction = this.getOrganizationsForTypeAhead.bind(this);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    _organizationService = inject(OrganizationService);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    _tabSharedService = inject(TabSharedService);
    minimumCharactersToShow = 2;
    constructor(injector: Injector) {
        super(injector);
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    ngAfterViewInit(): void {
        this.subscriptions.add(
            this.getControl().valueChanges.subscribe((value) => {
                if (!value) {
                    this.typeAheadApi.clearValue();
                }
            }),
        );
        if (this.config.options) {
            this.typeAheadApi.setValue(this.config.options[0]);
        }
        super.ngAfterViewInit();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    getOrganizationsForTypeAhead(searchText: string): Observable<IOrganization[]> {
        const organizationTypeId = this._tabSharedService.getOrganizationTypeId();
        const organizations = this._organizationService.getOrganizationsByTypeNameFilter(organizationTypeId, searchText);
        return organizations;
    }

    organizationSelected(selected: ISelectionChangedEvent): void {
        const virtualSelectedValue = selected.selection;
        if (virtualSelectedValue) {
            const organizationId = virtualSelectedValue.Id;
            this._tabSharedService.setOrganizationName(virtualSelectedValue.Name);
            this.getControl().setValue(organizationId);
            
        }
    }
}