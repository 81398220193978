import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment-timezone';

@Pipe({
  name: 'formatToTimezone'
})
export class FormatToTimezonePipe implements PipeTransform {
  transform(date: Date | string, timezone = 'America/New_York', format = 'MMM D, YYYY, h:mm:ss A'): string {
    if (!date) {
        return '';
    }
    if (typeof date === 'string') { date = new Date(date); }

    const momentDate = moment(date);
    const convertedMoment = timezone ? momentDate.tz(timezone) : momentDate.local();
    const formattedDate = convertedMoment.format(format);

    return formattedDate;
  }
}
