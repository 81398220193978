import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '@mt-ng2/base-service';
import { IEvent } from '../../../model/core/interfaces/event';
import { Subject } from 'rxjs';

export const emptyEvent: IEvent = {
    Name: '',
    EventTypeId: 0,
    EventStatusId: 0,
    EventNumber: 0,
    Archived: false,
    Id: 0,
    SeasonId: 0,
    CreatedById: 0,
    Timezone: '',
    Code: '',
    Subtitle: '',
    StartDate: null,
    EndDate: null,
    ReleaseDate: null,
    RegistrationStartDate: null,
    RegistrationEndDate: null,
    DateCreated: null
};

@Injectable({
    providedIn: 'root',
})
export class EventService extends BaseService<IEvent> {
    constructor(public http: HttpClient) {
        super('/events', http, [], { entityName: 'Events' });
    }

    getEmptyEvent(): IEvent {
        return { ...emptyEvent };
    }

    copyEventToSeason(eventId: number, seasonToCopyToId: number) {
        return this.http.post<void>(`/events/${eventId}/season-to-copy/${seasonToCopyToId}`, {});
    }

    private refreshEventListSubject = new Subject<void>();
    refreshEventListSubject$ = this.refreshEventListSubject.asObservable();
    refreshEventList() {
        this.refreshEventListSubject.next();
    }
}
