<ng-container>
    <div class="miles-card padded" *ngIf="!showUploadArea">
        <h4>Documents</h4>
        <mt-entity-list
            [entities]="documentArray"
            [entityListConfig]="_entityListConfig"
            [total]="total"
            [(currentPage)]="currentPage"
            [(itemsPerPage)]="itemsPerPage"
            (itemSelectedEvent)="setEdit($event)"
            (columnSelectedEvent)="downloadDocument($event)"
            (itemDeletedEvent)="deleteDocument($event)"
            (columnSortedEvent)="columnSorted($event)">
        </mt-entity-list>
        <div *ngIf="canAdd" class="fab-wrap">
            <button type="button" class="btn btn-primary btn-fab-md btn-fab-center" (click)="setAdd()">
                <span class="fa fa-plus"></span>
            </button>
        </div>
    </div>
    <div *ngIf="showUploadArea" class="miles-form padded">
        <h4 [hidden]="showUploadDocument">Documents</h4>
        <div *ngIf = "!documentUpdated && !showUploadDocument">
            <span>{{ selectedDocumentFile.Name }}</span>
            <span *ngIf="canEdit" class="fa fa-fw fa-trash text-danger icon-action" (mtConfirm)="deleteDocumentDoc()"></span>
        </div>
        <mt-document
            *ngIf="showUploadDocument && documentUploadControlConfig.maxFileSize"
            [allowedMimeType]="documentUploadControlConfig.allowedMimeType"
            [maxFileSize]="documentUploadControlConfig.maxFileSize"
            (afterAddingFile)="addFile($event)"
            (addingFileFailed)="addingFailed($event)"
        ></mt-document>
        <mt-document
            *ngIf="showUploadDocument && !documentUploadControlConfig.maxFileSize"
            [allowedMimeType]="documentUploadControlConfig.allowedMimeType"
            (afterAddingFile)="addFile($event)"
            (addingFileFailed)="addingFailed($event)"
        ></mt-document>
        <br />
        <mt-dynamic-form [config]="documentFormFields" [form]="documentForm" (submitted)="formSubmitted($event)" (formCreated)="documentFormCreated($event)">
            <br>
            <button type="submit" mtDisableClickDuringHttpCalls class="btn btn-flat btn-primary">
                Save
            </button>
            <button type="button" class="btn btn-flat btn-default" (click)="cancel()">Cancel</button>
        </mt-dynamic-form>
    </div>
</ng-container>
