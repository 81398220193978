export const userPathSlugs = {
    root: 'users',
    add: 'add',
    myProfile: 'my-profile',
};

export const userPaths = {
    users: userPathSlugs.root,
    usersAdd: `${userPathSlugs.root}/${userPathSlugs.add}`,
    myProfile: `${userPathSlugs.root}/${userPathSlugs.myProfile}`,
};
