import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from '@mt-ng2/base-service';
import { IUser } from '@model/interfaces/user';
import { ICreateUserPayload } from '@model/interfaces/custom/create-user-payload';
import { IAddress } from '@mt-ng2/dynamic-form';
import { IPhoneCollection } from '@mt-ng2/entity-components-phones';
import { IImage } from '@model/interfaces/image';
import { IMtBlobFile } from '@mt-ng2/photo-control';
import { IUserActiveList } from '@model/interfaces/custom/user-active-list';
import { IUserEventPartnerPayload } from '@model/interfaces/custom/user-event-partner-payload';
import { IRegionalSupportManagerPayload } from '@model/interfaces/custom/regional-support-manager-payload';

@Injectable({
    providedIn: 'root',
})
export class UserService extends BaseService<IUser> {
    private emptyUser: IUser = {
        AuthUserId: 0,
        Email: null,
        FirstName: null,
        Id: 0,
        LastName: null,
        Timezone: null,
        DateOfBirth: null,
        StateId: null,
        CountryCode: null,
        EmailVerified: true,
        IsRegionalSupportManager: false,
    };

    constructor(public http: HttpClient) {
        super('/users', http, null, { entityName: 'User' });
    }

    formatTitleText(entity: IUser): void {
        this.setTitle(`User: ${entity.FirstName} ${entity.LastName}`);
    }

    getEmptyUser(): IUser {
        return { ...this.emptyUser };
    }

    createUser(data: ICreateUserPayload): Observable<number> {
        return this.http.post<number>(`/users/create`, data);
    }

    saveAddress(userId: number, address: IAddress): Observable<number> {
        if (!address.Id) {
            address.Id = 0;
            return this.http.post<number>(`/users/${userId}/address`, address);
        } else {
            return this.http.put<number>(`/users/${userId}/address`, address);
        }
    }

    deleteAddress(userId: number): Observable<object> {
        return this.http.delete(`/users/${userId}/address`, {
            responseType: 'text' as 'json',
        });
    }

    savePhones(userId: number, phones: IPhoneCollection): Observable<number> {
        return this.http.put<number>(`/users/${userId}/phones`, phones);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    savePhoto(userId: number, photo: IMtBlobFile): Observable<IImage> {
        const formData: FormData = new FormData();
        formData.append('file', photo, photo.name);

        return this.http.post<IImage>(`/users/${userId}/pic`, formData);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    deletePhoto(userId: number): Observable<any> {
        return this.http.delete(`/users/${userId}/pic`);
    }

    verifyEmail(verificationKey: string, userId: number): Observable<void> {
        const formData: FormData = new FormData();
        formData.append('verificationKey', verificationKey);
        return this.http.post<void>(`/users/${userId}/verify-email`, formData);
    }

    getActiveContactUsers() {
        return this.http.get<IUser[]>(`/users/get-active-contact`);
    }

    getActiveContactUsersFilter(searchText: string): Observable<IUserActiveList[]> {
        const formData: FormData = new FormData();
        formData.append('searchText', searchText);
        return this.http.post<IUserActiveList[]>(`/users/get-active-contact-by-filter`, formData);
    }

    saveEventPartnerPanel(userId: number, payload: IUserEventPartnerPayload): Observable<void> {
        return this.http.post<void>(`/users/${userId}/save-event-partner-panel`, payload);
    }

    saveRegionalSupportManagerPanel(userId: number, payload: IRegionalSupportManagerPayload): Observable<void> {
        return this.http.post<void>(`/users/${userId}/save-regional-support-manager-panel`, payload);
    }
}
