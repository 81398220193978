import { Component, inject, Injector, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Observable, map } from 'rxjs';
import { VirtualTypeAheadGetItemsFunction } from '@mt-ng2/type-ahead-control';
import { ISelectionChangedEvent, ITypeAheadAPI } from '@mt-ng2/type-ahead-control';
import { CustomFormControlComponentBase } from '@mt-ng2/dynamic-form';
import { IUserActiveList } from '@model/interfaces/custom/user-active-list';
import { UserService } from 'admin-portal/users/user.service';

@Component({
    templateUrl: './user-list-type-ahead-input.component.html',
})
export class UserListTypeAheadInputComponent extends CustomFormControlComponentBase implements OnInit, AfterViewInit, OnDestroy {
    typeAheadApi: ITypeAheadAPI;
    getItemsForTypeAhead: VirtualTypeAheadGetItemsFunction = this.getUsersForTypeAhead.bind(this);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    _userService = inject(UserService);
    constructor(injector: Injector) {
        super(injector);
    }
    list: IUserActiveList[] = [];
    userActiveList$: Observable<IUserActiveList[]>;

    ngOnInit(): void {
        super.ngOnInit();
    }

    ngAfterViewInit(): void {
        this.subscriptions.add(
            this.getControl().valueChanges.subscribe((value) => {
                if (!value) {
                    this.typeAheadApi.clearValue();
                }
            }),
        );
        if (this.config.options) {
            this.typeAheadApi.setValue(this.config.options[0]);
        }
        super.ngAfterViewInit();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    getUsersForTypeAhead(searchText: string): Observable<IUserActiveList[]> {
        return this._userService.getActiveContactUsersFilter(searchText).pipe(
            map((data) => {
                if (this.config.customOptions?.edit) {
                    return data.length === 0 ? this.functionForNonUser(searchText) : data;
                } else {
                    return data;
                }
            }),
        );
    }

    userSelected(selected: ISelectionChangedEvent): void {
        const virtualSelectedValue = selected.selection;
        if (virtualSelectedValue) {
            let userId = virtualSelectedValue.Id;
            if (this.config.customOptions?.edit) {
                userId = virtualSelectedValue;
            }
            this.getControl().setValue(userId);
        }
    }

    private functionForNonUser(newEntry: string) {
        return [{ Name: newEntry, Id: 0 }];
    }
}
