import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from '@mt-ng2/base-service';
import { IOrganization } from '@model/interfaces/organization';
import { IAddress } from '@mt-ng2/dynamic-form';

export const emptyOrganization: IOrganization = {
    Name: '',
    OrganizationTypeId: 0,
    Archived: false,
    Id: 0,
    Teams: [],
    OrganizationTeamNumbers: [],
};

@Injectable({
    providedIn: 'root',
})
export class OrganizationService extends BaseService<IOrganization> {
    constructor(public http: HttpClient) {
        super('/organizations', http, null, { entityName: 'Organizations' });
    }

    getEmpty(): IOrganization {
        return { ...emptyOrganization };
    }

    getByOrganizationType(organizationTypeId: number) {
        return this.http.get<IOrganization[]>(`/organizations/${organizationTypeId}/get-organizations-by-type`);
    }

    getOrganizations(): Observable<IOrganization[]> {
        return this.http.get<IOrganization[]>(`/organizations/get-all`);
    }

    getAddress(organizationTypeId: number): Observable<IAddress> {
        return this.http.get<IAddress>(`/organizations/${organizationTypeId}/get-address`);
    }

    hasOrganization(organizationTypeId: number): Observable<boolean> {
        return this.http.get<boolean>(`/organizations/${organizationTypeId}/has-organization`);
    }

    getOrganizationsByTypeNameFilter(organizationTypeId: number, organizationName: string): Observable<IOrganization[]> {
        const formData: FormData = new FormData();
        formData.append('organizationName', organizationName);
        return this.http.post<IOrganization[]>(`/organizations/${organizationTypeId}/get-organizations-by-filter/`, formData);
    }

    saveAddress(organizationsId: number, address: IAddress): Observable<number> {
        if (!address.Id) {
            address.Id = 0;
            return this.http.post<number>(`/organizations/${organizationsId}/address`, address);
        } else {
            return this.http.put<number>(`/organizations/${organizationsId}/address`, address);
        }
    }

    deleteAddress(organizationsId: number): Observable<object> {
        return this.http.delete(`/organizations/${organizationsId}/address`, {
            responseType: 'text' as 'json',
        });
    }
}
